<template>
	<div>
		<template>
			<v-container>
				<SettingsGear :showIcon="true" />
				<div style="height: 15vh">
					<div
						style="
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 2vh;
            "
					>
						<img
							src="@/assets/logos/dime_logo_gold.png"
							style="height: 7em"
						/>
					</div>
					<div
						style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: flex-end;
            "
					>
						<!-- <img
              src="@/assets/logos/dime_thinkhigher.png"
              style="height: 2.5em"
            /> -->
					</div>
				</div>
				<div style="height: 66vh; overflow: hidden">
					<div style="margin-top: 1vh"></div>
					<div class="faq-title">FAQS & Troubleshooting</div>
					<div class="faq-list" v-if="faqList.length > 0">
						<div
							class="faq-item"
							v-for="item of faqList"
							:key="item.id"
							@click="onClickItemDetails(item)"
						>
							<span>{{ item.title }} </span>
							<img
								class="faq-item-right"
								src="@/assets/ui/right.png"
							/>
						</div>
					</div>
					<div v-else>
						<div class="faq-list-none">
							The FAQ list has not been updated
						</div>
					</div>
				</div>
				<Footer />
			</v-container>
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Toast } from 'vant'

export default {
	name: 'faq',
	data() {
		return {
			faqList: [
				{
					id: 1,
					title: 'Step By Step Guide',
					content:
						'Turning on/off device\n Plugging cart into device\n Warnings/connections\n Connecting to app',
					imgList: [
						require('@/assets/bg/placeholder.png'),
						require('@/assets/bg/placeholder.png'),
					],
				},
				{
					id: 2,
					title: 'How do I turn on my device?',
					content: 'Click “fire” button 5 times to turn on or off',
					imgList: [require('@/assets/bg/placeholder.png')],
				},
				{
					id: 3,
					title: 'I have issues connecting my device',
					content:
						'Make sure bluetooth is enabled\n Check that device is powered on, and in range',
					imgList: [require('@/assets/bg/placeholder.png')],
				},
				{
					id: 4,
					title: 'Presets',
					content:
						'The “presets” view is our recommended temperature for various concentrates.\n Each preset can be customized to your preferred temperature',
					imgList: [require('@/assets/bg/placeholder.png')],
				},
				{
					id: 5,
					title: 'How do I clean my device?',
					content:
						'Bowl: Q-tip & alcohol (isopropyl)\n Coil: Pulse “fire” button until all excess oil has been burned off of the coil',
					imgList: [require('@/assets/bg/placeholder.png')],
				},
				{
					id: 6,
					title: 'What does my coil life mean?',
					content:
						'Estimated amount of time until a new coil needs to be purchased',
					imgList: [require('@/assets/bg/placeholder.png')],
				},
				{
					id: 7,
					title: "What's the difference between GENTLE vs INSANE?",
					content:
						'The “INSANE” mode’s increased air draw\n will result in larger hits “GENTLE” mode’s\n air draw is tighter, resulting in a smaller hits, but will have more flavor',
					imgList: [require('@/assets/bg/placeholder.png')],
				},
			],
		}
	},
	created() {
		let params = {
			pageNum: 1,
			pageSize: 1000,
		}
		// this.$api.cartridge
		//   .commonProblemList(params)
		//   .then((res) => {
		//     if (res.code === 200) {
		//       this.faqList = res.data;
		//     } else {
		//       this.$showTips({
		//         msg: res.message,
		//       });
		//       // Toast.fail(res.message);
		//     }
		//   })
		//   .catch((res) => {
		//     this.$showTips({
		//       msg: res.message,
		//     });
		//     // Toast.fail(res.message);
		//   });
	},
	methods: {
		onClickItemDetails(item) {
			this.$router.push({
				name: 'faqDetails',
				query: { item: item },
			})
		},
	},
	computed: {
		...mapGetters(['getMainColor', 'getAccentColor']),
	},
}
</script>

<style lang="less" scoped>
.faq-title {
	color: #ffffff;
	font-size: 2.5vh;
	text-align: center;
	font-weight: bold;
}

.faq-list-none {
	color: #ffffff;
	font-size: 2.5vh;
	text-align: center;
	font-weight: bold;
	margin-top: 40%;
}

.faq-list {
	padding: 0 1vh;
	margin-top: 4vh;
	height: 57vh;
	overflow: auto;

	.faq-item {
		height: 6vh;
		display: flex;
		border-radius: 30px;
		border: 2px solid #ffab00;
		margin-bottom: 1.5vh;
		align-items: center;
		padding: 0 13px;

		span {
			width: 92%;
			color: #ffffff;
			font-size: 2vh;
			line-height: 2.4vh;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.faq-item-right {
			width: 2vh;
			margin-left: auto;
		}
	}
	.faq-item:active {
		background: #ffab00;
	}
	.faq-item-right {
		color: #ffab00;
		margin-left: auto;
		font-size: 2.5vh;
	}
}
</style>
